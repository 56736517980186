import React from "react";
import styles from "./TopicHead.module.css";

type Props = {
    title: React.ReactNode;
};

const TopicHead = ({title} : Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.headtext}>{title}</h1>
    </div>
  );
};

export default TopicHead;