import { ethers } from 'ethers';
import { useContract, useSigner } from 'wagmi';
import WETHArtifact from '../utils/abis/WETH.json';
import { CONFIG } from '../config'; // Import config

const useWETH = () => {
  const { data: signer } = useSigner();
  const WETHContract = useContract({
    address: CONFIG.WRAP_ADDRESS,
    abi: WETHArtifact.abi,
    signerOrProvider: signer,
  });

  const deposit = async (amount: number) => {
    if (!WETHContract)
      throw new Error('WETH contract has not been initialized');

    const parsedAmount = ethers.utils.parseUnits(amount.toString(), CONFIG.DECIMALS);
    const txn = await WETHContract.deposit({ value: parsedAmount });
    await txn.wait();
    return txn;
  };

  const approve = async (spender: string, amount: number) => {
    if (!WETHContract)
      throw new Error('WETH contract has not been initialized');

    const parsedAmount = ethers.utils.parseUnits(amount.toString(), CONFIG.DECIMALS);
    const txn = await WETHContract.approve(spender, parsedAmount);
    await txn.wait();
    return txn;
  };

  const withdraw = async (amount: number) => {
    if (!WETHContract)
      throw new Error('WETH contract has not been initialized');

    const parsedAmount = ethers.utils.parseUnits(amount.toString(), CONFIG.DECIMALS);
    const txn = await WETHContract.withdraw(parsedAmount);
    await txn.wait();
    return txn;
  };

  return { deposit, approve, withdraw };
};

export default useWETH;