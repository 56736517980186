import React, { useEffect } from 'react';
import { useAccount, useConnect, useEnsName, useNetwork, useSwitchNetwork, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import {
  Box,
  Flex,
  Text,
  Image,
  Link,
  Button as ChakraButton,
  IconButton,
  HStack,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { CONFIG } from '../../config';
import Logo from '../../assets/Beastica_Logo.png';
import NavbarBackground from '../../assets/NAV_Bar.png';
import Button from '../Button/Button';
import YellowButton from '../YellowButton/YellowButton';

const Header: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isConnected && chain?.id !== CONFIG.TARGET_CHAIN_ID && switchNetwork) {
      switchNetwork(CONFIG.TARGET_CHAIN_ID);
    }
  }, [isConnected, chain, switchNetwork]);

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      p="1rem 2rem"
      bgImage={`url(${NavbarBackground})`}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="center"
      wrap="wrap"
    >
      <Flex alignItems="center">
        <Image src={Logo} alt="Beastica Logo" height="40px" ml="150px" mt={10} />

        {/* Desktop Menu - Wrap & Stake links */}
        <Link href="/wrap" mx={50} mt={10}>
          <Text color="white">Wrap</Text>
        </Link>
        <Link href="/stake" mt={10}>
          <Text color="white">Transfer</Text>
        </Link>
      </Flex>

      <Box mr={150} mt={10}>
        {isConnected ? (
          <Flex alignItems="center" gap="1rem">
            <Text fontSize="md" color="white">
              Connected to {ensName ? `${ensName}` : address}
            </Text>
            <YellowButton onClick={() => disconnect()}>Disconnect</YellowButton>
          </Flex>
        ) : (
          <YellowButton onClick={() => connect()}>Connect Wallet</YellowButton>
        )}
      </Box>
    </Flex>
  );
};

export default Header;