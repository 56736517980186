import { useContract, useSigner, useAccount } from 'wagmi';
import { ethers } from 'ethers';
import stakingContractABI from '../utils/abis/StakingContract.json'; // Replace with the actual path to your ABI
import { parseUnits, formatUnits } from 'ethers/lib/utils';
import { CONFIG } from '../config'; // Import config

const useStake = () => {
  const { data: signer } = useSigner();
  const { address } = useAccount();

  const stakingContract = useContract({
    address: CONFIG.STAKING_CONTRACT_ADDRESS as `0x${string}`,
    abi: stakingContractABI.abi,
    signerOrProvider: signer,
  });

  const deposit = async (amount: number) => {
    if (!stakingContract) throw new Error('Staking contract is not initialized');
    const parsedAmount = parseUnits(amount.toString(), CONFIG.DECIMALS);
    const tx = await stakingContract.deposit(parsedAmount);
    await tx.wait();
    return tx;
  };

  const withdraw = async (amount: number) => {
    if (!stakingContract) throw new Error('Staking contract is not initialized');
    const parsedAmount = parseUnits(amount.toString(), CONFIG.DECIMALS);
    const tx = await stakingContract.withdraw(parsedAmount);
    await tx.wait();
    return tx;
  };

  const getStakedBalance = async () => {
    if (!stakingContract) throw new Error('Staking contract is not initialized');
    const balance = await stakingContract.balanceOf(address);
    return formatUnits(balance, CONFIG.DECIMALS);
  };

  return { deposit, withdraw, getStakedBalance };
};

export default useStake;