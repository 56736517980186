import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Add this line
import { configureChains, createClient, WagmiConfig, Chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { Box, Flex } from '@chakra-ui/react'; // Import Chakra UI components
import './App.css';
import Background from './assets/background.png';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppRoutes from './Routes'; // Import your AppRoutes component
import { CONFIG } from './config'; // Import config

const jfinTestnet: Chain = {
  id: CONFIG.TARGET_CHAIN_ID,
  name: CONFIG.CHAIN_NAME,
  network: CONFIG.NETWORK_NAME,
  nativeCurrency: {
    name: CONFIG.CHAIN_NAME,
    symbol: CONFIG.CHAIN_SYMBOL,
    decimals: CONFIG.DECIMALS,
  },
  rpcUrls: {
    default: CONFIG.RPC_URL,
  },
  blockExplorers: {
    default: { name: CONFIG.EXPLORERS_NAME, url: CONFIG.BLOCK_EXPLORER_URL },
  },
  testnet: CONFIG.TEST_NET,
};

const { provider, webSocketProvider } = configureChains(
  [jfinTestnet],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: [new InjectedConnector({ chains: [jfinTestnet] })],
});

function App() {
  return (
    <WagmiConfig client={client}>
      <Router> {/* Add this Router component here */}
        {/* Full height flexbox layout */}
        <Flex direction="column" minHeight="100vh" bgImage={`url(${Background})`}>
          {/* Header */}
          <Header />

          {/* Main Content */}
          <Box as="main" flex="1" mb={500}>
            <AppRoutes /> {/* Your routes component */}
          </Box>

          {/* Footer */}
          <Footer />
        </Flex>
      </Router> {/* Close the Router */}
    </WagmiConfig>
  );
}

export default App;