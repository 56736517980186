import React from "react";
import { Button, ButtonGroup } from '@chakra-ui/react'
import styles from "./YellowButton.module.css";

type Props = {
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
};

const YellowButton = ({ onClick, children, disabled }: Props) => {
  return (
    <Button variant='link' className={styles.yellowButton} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default YellowButton;