import React, { useState, useEffect } from 'react';
import { useAccount, useBalance, useContract, useSigner } from 'wagmi';
import useStake from '../../hooks/useStake'; 
import styles from './StakeCard.module.css';
import Spinner from '../Spinner/Spinner';
import { ethers } from 'ethers';
import ERC20_ABI from '../../utils/abis/gtoken.json'; // Ensure ABI is correctly imported
import { CONFIG } from '../../config';
import TopicHead from '../TopicHead/TopicHead';
import { Box, Button, Flex, Input, Text, Spacer } from '@chakra-ui/react';
import bgImgPath from '../../assets/bg-card.png';
import YellowButton from '../YellowButton/YellowButton';
import SuccessModal from './SuccessModal';
import FailureModal from './FailureModal';

// Ensure ABI is correctly typed as readonly
const WRAPPED_TOKEN_ADDRESS = CONFIG.WRAP_ADDRESS as `0x${string}`;
const STAKING_CONTRACT_ADDRESS = CONFIG.STAKING_CONTRACT_ADDRESS;

const StakeCard: React.FC = () => {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [stakedBalance, setStakedBalance] = useState('0');
  const [allowance, setAllowance] = useState('0');
  const { address } = useAccount();
  const { data: signer } = useSigner();
  
  // Correct usage of useBalance with addressOrName
  const { data: WETHBalance, refetch: refetchWETHBalance } = useBalance({
    addressOrName: address || '', // Ensure address is defined
    token: WRAPPED_TOKEN_ADDRESS,
    watch: true,
  });

  const { deposit, withdraw, getStakedBalance } = useStake();

  // Correct usage of useContract with address and abi
  const tokenContract = useContract({
    address: WRAPPED_TOKEN_ADDRESS,
    abi: ERC20_ABI, // Ensure ABI is correctly imported
    signerOrProvider: signer,
  });

  const updateStakedBalance = async () => {
    try {
      const balance = await getStakedBalance();
      setStakedBalance(balance);
    } catch (error) {
      console.error('Error fetching staked balance:', error);
    }
  };

  const updateAllowance = async () => {
    if (!tokenContract) {
      console.error('Token contract is not initialized');
      return;
    }
    try {
      const allowance = await tokenContract.allowance(address, STAKING_CONTRACT_ADDRESS);
      setAllowance(ethers.utils.formatUnits(allowance, 18));
    } catch (error) {
      console.error('Error fetching allowance:', error);
    }
  };

  useEffect(() => {
    if (address) {
      updateStakedBalance();
      updateAllowance();
    }
  }, [address]);



  const onChangeAmountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(event.target.value));
  };

  const approveTokens = async (amountToApprove: number) => {
    if (!tokenContract) {
      alert('Token contract is not initialized');
      return;
    }
    try {
      setIsLoading(true);
      const tx = await tokenContract.approve(
        STAKING_CONTRACT_ADDRESS,
        ethers.utils.parseUnits(amountToApprove.toString(), 18)
      );
      await tx.wait();
      alert('Approval successful');
      updateAllowance();
    } catch (error) {
      alert('Approval failed');
      console.error('Approval failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickStakeButton = async () => {
    if (!amount) {
      alert('Please enter an amount to stake.');
      return;
    }

    if (parseFloat(allowance) < amount) {
      alert('Insufficient allowance. Approving tokens...');
      await approveTokens(amount);
    }

    setIsLoading(true);
    try {
      await deposit(amount);
      setIsSuccessModalOpen(true);
      refetchWETHBalance();
      updateStakedBalance();
    } catch (error) {
      setErrorMessage("Failed to Stake GToken.");
      setIsFailureModalOpen(true);
      console.error('Stake failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickWithdrawButton = async () => {
    if (!amount) {
      alert('Please enter an amount to withdraw.');
      return;
    }

    setIsLoading(true);
    try {
      await withdraw(amount);
      setIsSuccessModalOpen(true);
      refetchWETHBalance();
      updateStakedBalance();
    } catch (error) {
      setErrorMessage("Failed to Withdraw GToken.");
      setIsFailureModalOpen(true);
      console.error('Withdraw failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToWrap = () => {
    console.log("Navigating to wrap JFIN page...");
    setIsFailureModalOpen(false);
  };

  return (
    <Box
      p="2rem"
      position="absolute"
      top="40%"
      left="50%"
      transform="translate(-50%, -50%)"
      borderRadius="0.75rem"
      width="400px"
      display="flex"
      flexDirection="column"
      gap="2rem"
      w='60%'
    >
      <Box w='100%'><TopicHead title="Transfer to game currency" /></Box>
      <Box
        bgImage={bgImgPath}
        bgSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        padding="1rem"
      >
        <Flex>
          <Input
            placeholder="Amount"
            disabled={!address}
            onChange={onChangeAmountInput}
            flex="1"
            padding="0.5rem 1rem"
            border="1px solid"
            borderRadius="1.25rem"
            borderColor="var(--tertiary-background-color)"
            fontSize="2.75rem"
            // bg="transparent"
          />
        </Flex>
        <Text fontSize="2.75rem" mt="0.25rem" ml="1rem" color="#000">GToken Balance: {WETHBalance?.formatted}</Text>
        <Text fontSize="2.75rem" mt="0.25rem" ml="1rem" color="#000" mb="3%">In Game Balance: {stakedBalance}</Text>
        {/* <Text fontSize="2.75rem" mt="0.25rem" ml="1rem" color="#000">Allowance: {allowance}</Text> */}
        <Box
          display="flex"
          justifyContent="space-evenly"
          w="100%"
          >
          <YellowButton disabled={!address} onClick={onClickStakeButton}>
            <Text fontSize="2rem">Deposit</Text>
          </YellowButton>
          <YellowButton disabled={!address} onClick={onClickWithdrawButton}>
            <Text fontSize="2rem">Withdraw</Text>
          </YellowButton>
        </Box>
      </Box>
      {isLoading && <Spinner />}
       {/* Success Modal */}
       <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        amount={amount}
        tokenSymbol="GToken"
      />

      {/* Failure Modal */}
      <FailureModal
        isOpen={isFailureModalOpen}
        onClose={() => setIsFailureModalOpen(false)}
        onNavigate={navigateToWrap}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

export default StakeCard;