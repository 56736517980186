import React, { useState } from 'react';
import { useAccount, useBalance, useNetwork, useSwitchNetwork } from 'wagmi';
import useWETH from '../../hooks/useWrap';
import Spinner from '../Spinner/Spinner';
import { Box, Button, Flex, Input, Text, Spacer } from '@chakra-ui/react';
import { CONFIG } from '../../config';
import TopicHead from '../TopicHead/TopicHead';
import SuccessModal from './SuccessModal';
import FailureModal from './FailureModal';
import YellowButton from '../YellowButton/YellowButton';
import bgImgPath from '../../assets/bg-card.png';

const WETH_ADDRESS = CONFIG.WRAP_ADDRESS as `0x${string}`;
const TARGET_CHAIN_ID = CONFIG.TARGET_CHAIN_ID;

const WrapCard = () => {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { deposit, withdraw } = useWETH();
  const { address } = useAccount();
  const { data: ETHBalance } = useBalance({
    addressOrName: address,
    watch: true,
  });
  const { data: WETHBalance, refetch: refetchWETHBalance } = useBalance({
    addressOrName: address,
    token: WETH_ADDRESS,
    watch: true,
  });

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const onChangeAmountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(event.target.value));
  };

  const checkNetwork = async () => {
    if (chain?.id !== TARGET_CHAIN_ID) {
      if (switchNetwork) {
        await switchNetwork(TARGET_CHAIN_ID);
      } else {
        throw new Error('Unable to switch network');
      }
    }
  };

  const onClickWrapButton = async () => {
    if (!amount) {
      alert("Please enter an amount to wrap.");
      return;
    }

    setIsLoading(true);
    try {
      await checkNetwork();
      const tx = await deposit(amount);
      setTxHash(tx.transactionHash);
      refetchWETHBalance();
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage("Failed to Wrap Jfin.");
      setIsFailureModalOpen(true);
      console.error("Wrap failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickUnwrapButton = async () => {
    if (!amount) {
      alert("Please enter an amount to unwrap.");
      return;
    }

    setIsLoading(true);
    try {
      await checkNetwork();
      const tx = await withdraw(amount);
      setTxHash(tx.transactionHash);
      refetchWETHBalance();
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage("You do not have enough G Token to Unwrap.");
      setIsFailureModalOpen(true);
      console.error("Unwrap failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToWrap = () => {
    console.log("Navigating to wrap JFIN page...");
    setIsFailureModalOpen(false);
  };

  return (
    
    <Box
      p="2rem"
      position="absolute"
      top="40%"
      left="50%"
      transform="translate(-50%, -50%)"
      borderRadius="0.75rem"
      width="400px"
      display="flex"
      flexDirection="column"
      gap="2rem"
      w='60%'
    >
      <Box w='100%'><TopicHead title="Wrap" /></Box>
      

      <Box
        bgImage={bgImgPath}
        bgSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        padding="1rem"
      >
        <Flex>
          <Input
            placeholder="Amount"
            disabled={!address}
            onChange={onChangeAmountInput}
            flex="1"
            padding="0.5rem 1rem"
            border="1px solid"
            borderRadius="1.25rem"
            borderColor="var(--tertiary-background-color)"
            fontSize="2.75rem"
            // bg="transparent"
          />
        </Flex>
        <Text fontSize="2.75rem" mt="0.25rem" ml="1rem" color="#000">
          JFIN Balance: {ETHBalance?.formatted}
        </Text>
        <Text fontSize="2.75rem" mt="0.25rem" ml="1rem" color="#000" mb="3%">
          GToken Balance: {WETHBalance?.formatted}
        </Text>
      
        <Box
          display="flex"
          justifyContent="space-evenly"
          w="100%"
          >
          <YellowButton disabled={!address} onClick={onClickWrapButton} >
            <Text fontSize="2rem">Wrap</Text>
          </YellowButton>
          <YellowButton disabled={!address} onClick={onClickUnwrapButton} >
          <Text fontSize="2rem">Unwrap</Text>
          </YellowButton>
        </Box>
      </Box>

      {isLoading && <Spinner />}

      {/* Success Modal */}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        txHash={txHash}
        amount={amount}
        tokenSymbol="GToken"
        explorerUrl={`https://etherscan.io/tx/${txHash}`}
      />

      {/* Failure Modal */}
      <FailureModal
        isOpen={isFailureModalOpen}
        onClose={() => setIsFailureModalOpen(false)}
        onNavigate={navigateToWrap}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

export default WrapCard;