import React from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Text, Link } from '@chakra-ui/react';
import YellowButton from '../YellowButton/YellowButton';
import TopicHead from '../TopicHead/TopicHead';

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  amount: number;
  tokenSymbol: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, amount, tokenSymbol }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0,0, 0.4)"/>
      <ModalContent
        my="10%"
        mx="auto"
        bg="#F9F9F9"
        borderRadius="10px"
        textAlign="center"
        minH="300px"
        maxW="500px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding={20}
        alignSelf='center'
        
      >
        <TopicHead title="Sucessful" ></TopicHead>
        <ModalBody>
          <Text fontSize="2rem" my="10%" fontWeight="bold" color="brown">
            {amount.toLocaleString()} {tokenSymbol}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center">
        
          <YellowButton 
            onClick={onClose}
          >
            <Text fontSize="1.5rem">Confirm</Text>
          </YellowButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;