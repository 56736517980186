import { Routes as RouterRoutes, Route } from 'react-router-dom';
import TabbedInterface from './components/Tabbed/TabbedInterface';
import WrapCard from './components/WrapCard/WrapCard';
import StakeCard from './components/StakeCard/StakeCard';

const AppRoutes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<WrapCard />} />
      <Route path="/wrap" element={<WrapCard />} />
      <Route path="/stake" element={<StakeCard />} />
    </RouterRoutes>
  );
};

export default AppRoutes;