import React from 'react';
import { Box, Flex, Text, Image, Link, VStack, HStack, Divider } from '@chakra-ui/react';
import Logo from '../../assets/Beastica_Logo.png';


const Footer = () => {
  return (
    <Box as="footer" bg="#401F00" color="rgba(158, 202, 255, 0.8)" py="2rem" fontFamily="'Poppins', sans-serif">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        wrap="wrap"
        mb="2rem"
        px="2rem"
      >
        {/* Logo */}
        <Box mb={{ base: '2rem', md: '0' }}>
          <Image src={Logo} alt="Logo" h="40px" />
        </Box>

        {/* Links Container */}
        <Flex direction={{ base: 'column', md: 'row' }} gap="2rem" wrap="wrap">
          {/* About Section */}
          <VStack align="flex-start">
            <Text color="white" fontSize="1rem" fontWeight="500">
              About
            </Text>
            <Link href="#gitbook" _hover={{ color: 'white' }}>Gitbook</Link>
            <Link href="#whitepaper" _hover={{ color: 'white' }}>Whitepaper</Link>
            <Link href="#faq" _hover={{ color: 'white' }}>FAQ</Link>
            <Link href="#blog" _hover={{ color: 'white' }}>Blog</Link>
          </VStack>

          {/* Community Section */}
          <VStack align="flex-start">
            <Text color="white" fontSize="1rem" fontWeight="500">
              Community
            </Text>
            <Link href="#x" _hover={{ color: 'white' }}>X</Link>
            <Link href="#medium" _hover={{ color: 'white' }}>Medium</Link>
            <Link href="#discord" _hover={{ color: 'white' }}>Discord</Link>
            <Link href="#telegram" _hover={{ color: 'white' }}>Telegram</Link>
          </VStack>
        </Flex>
      </Flex>

      <Divider borderColor="#243B5E" />

      {/* Footer Bottom */}
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap"
        mt="1rem"
        px="2rem"
        py="1rem"
      >
        <Text fontSize="0.8rem">Copyright © 2024. All rights reserved.</Text>

        <HStack spacing="1rem">
          <Link href="#terms-of-use" fontSize="0.7rem" _hover={{ color: 'white' }}>Terms of Use</Link>
          <Link href="#terms-of-service" fontSize="0.7rem" _hover={{ color: 'white' }}>Terms of Service</Link>
          <Link href="#privacy-policy" fontSize="0.7rem" _hover={{ color: 'white' }}>Privacy Policy</Link>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Footer;