import React from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Text, Image, Link } from '@chakra-ui/react';
import YellowButton from '../YellowButton/YellowButton';
import TopicHead from '../TopicHead/TopicHead';
import imgPath from '../../assets/pic-failed.png';

interface FailureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNavigate: () => void;
  errorMessage: string;
}

const FailureModal: React.FC<FailureModalProps> = ({ isOpen, onClose, onNavigate, errorMessage}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="rgba(0, 0,0, 0.4)"/>
      <ModalContent
        my="10%"
        mx="auto"
        bg="#F9F9F9"
        borderRadius="10px"
        textAlign="center"
        minH="300px"
        maxW="500px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding={20}
        alignSelf='center'
        
      >
        <TopicHead title="Failed"></TopicHead>
        <ModalBody>
          <Image src={imgPath} alt="Failure Image" mb={4} borderRadius="md" objectFit='contain' h="auto" w="90%" my="10%" />
          <Text fontSize="1.5rem" color="#000" mb="10" >
            {errorMessage}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <YellowButton 
            onClick={onClose}
          >
            <Text fontSize="1.5rem">Close</Text>
          </YellowButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FailureModal;